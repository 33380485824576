import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Text,
  VStack,
} from "@chakra-ui/react";
import LandingImage1 from "../assets/landing_image1.jpg";
import { HashLink as Link } from "react-router-hash-link";
export default function LandingBanner() {
  return (
    <Box
      pt="80px"
      height="95vh"
      width="120hw"
      display={["none", "none", "block"]}
    >
      <HStack justifyContent="space-between">
        <Image
          height="95vh"
          width="60%"
          fit="cover"
          src={LandingImage1}
          style={{
            clipPath: "polygon(0 0, 0 100%, 100% 100%, 80% 0)",
          }}
        ></Image>
        <VStack pr="6%" alignItems="end">
          <Heading textAlign="end" color={"#10454f"} fontWeight="extrabold">
            Helping children realise their dreams
          </Heading>
          <Text textAlign="end" pt="1rem" pb="2rem" width={"90%"}>
            Delivering <b>linguistic training</b> along with career counselling
            and financial support to underprivileged children, in efforts to
            uplift them.{" "}
          </Text>
          <Link to="/what-we-do/#">
            <Button
              m={7}
              size="lg"
              boxSizing="content-box"
              p="0.5rem 3rem"
              _expanded="false"
              backgroundColor="#8DC63F"
              color="white"
              _hover={{ backgroundColor: "#10454F" }}
            >
              READ MORE
            </Button>
            </Link>        
        </VStack>
      </HStack>
    </Box>
  );
}
