import React from "react";
// import CustomHeading from "../components/CustomHeading";
import { Heading, Box, Center, Container } from "@chakra-ui/react";
import UpChildFooter from "../components/CustomFooter";

const TermsAndConditions = () => {
  return (
    <div>
      <div style={{ overflowX: "clip" }}>
        <Box w="100%" pt="15vh"></Box>
        <Center>
          <Heading textAlign="end" color={"#10454f"} fontWeight="extrabold">
            Terms and Conditions
          </Heading>
        </Center>
        <Container
          align="justify"
          maxW="3xl"
          centerContent
          bg="white"
          w="100%"
          p={4}
          borderRadius="10px"
          borederwidth="1px"
        >
          <Heading
            as="h3"
            size="sm"
            textAlign="end"
            color={"#10454f"}
            fontWeight="bold"
            p="10px"
          >
            Use of the website
          </Heading>
          Your access to the use of this website is subjected to the following terms
          and conditions which are applicable by law. UPCHILD FOUNDATION is entitled
          to make changes in the Terms and Conditions as when required which will be
          updated on the website. You agree to use this site only for lawful purposes
          and in a manner which does not infringe the rights, or restrict, or inhibit
          the use of the site by any third party.<br/><br/>
          UPCHILD FOUNDATION does not take warrant if any material functions contained
          in the website will be uninterrupted or for any discontinuance. UPCHILD FOUNDATION
          does not take responsibility that the defects will be corrected, or the site
          will be error free or the reliability of the website materials.<br/><br/>
          <Heading
            as="h3"
            size="sm"
            textAlign="end"
            color={"#10454f"}
            fontWeight="bold"
            p="10px"
          >
            Copyright Restrictions
          </Heading>
          Commercial use of any or all items displayed on the website is strictly prohibited 
          without complete authorization of UPCHILD FOUNDATION.<br/><br/>
          UPCHILD FOUNDATION condemns the use of the documents for personal use without all
          the copyright notices, trademarks or other proprietary notice present in the document.
          No modifications made to the documents is encouraged by UPCHILD FOUNDATION.<br/><br/>
          Some documents and photographs have been used on this website with the permission of the
          copyright owners. Use of such documents or photographs can be copied by requesting
          the relevant copyright owners.<br/><br/>
          UPCHILD FOUNDATION is not responsible for any information or content present on the
          websites we link to. These websites are owned by third parties and UPCHILD FOUNDATION
          has no control over them. UPCHILD FOUNDATION does not approve or endorse any material
          which includes statements, products, information or services present on the third party
          websites.<br/><br/>
          UPCHILD FOUNDATION reserves the right to change the Terms and Conditions under which
          this UPCHILD FOUDNATION website is offered at any time and without prior notice.
          You will be automatically bound by the modifications when you use this UPCHILD FOUNDATION
          website and should periodically read the Terms and Conditions.<br/><br/>  
        </Container>
        <UpChildFooter />
      </div>
    </div>
  );
};
export default TermsAndConditions;
