import React from "react";
import { Heading, Box, Center, Text, Link } from "@chakra-ui/react";
import { NotAllowedIcon } from '@chakra-ui/icons'
import { HashLink as l } from "react-router-hash-link";

export default function Error() {
    const mystyle = {


    }
    return (
        <div style={{ overflowX: "clip" }}>
            <Box w="100%" pt="10vh"></Box>
            <Center>
                {/* Top 404 text */}

                <Heading textAlign="end" color={"#10454f"} fontWeight="extrabold" fontSize={"17vw"}>
                    4
                </Heading>
                <Heading textAlign="end" color={"#8DC63F"} fontWeight="extrabold" fontSize={"17vw"}>
                    <NotAllowedIcon />
                </Heading>
                <Heading textAlign="end" color={"#10454f"} fontWeight="extrabold" fontSize={"17vw"}>
                    4
                </Heading>


            </Center>

            {/* Middle text */}
            <Center>
                <Text color={"#10454f"} fontWeight="normal" fontSize={"5vw"}>
                    Opps something went wrong
                </Text>
            </Center>
            <Box w="100%" pt="5vh"></Box>
            {/* Bottom text */}
            <Center>
                <Text color={"#06283D"} fontWeight="hairline" fontSize={"3vw"}>
                    The page you are looking can't be found. Go home by{' '}
                    <Link href="/#" color={"#8DC63F"} fontWeight="hairline" fontSize={"3vw"} textDecorationLine="underline">
                        clicking here
                    </Link>
                </Text>

            </Center>


        </div>
    );
}