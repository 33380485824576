import { Center, Flex, Text } from "@chakra-ui/react";
import CustomHeading from "./CustomHeading";
export default function WhoAreWe() {
  return (
    <div>
    <Flex id="about" w="full" className="anchor-about"></Flex>
      <Flex
        
        w="full"
        minHeight={"20vh"}
        pt={["7vh", "20vh"]}
        direction={"column"}
      >
        <Flex direction="column" px="4rem">
          <CustomHeading heading="Who are we?"></CustomHeading>
          <Center>
          <Flex w="70vw">
            <Text
              display={["block", "none"]}
              fontSize="19"
              textAlign="justify"
              mx="-8"
              px={8}
              py="6"
              bgColor="gray.100"
              borderRadius={10}
            >
              UpChild Foundation™ is a Bengaluru based non-profit whose goal is
              to help underprivileged children lead a more comfortable city
              life. Our non-profit not only focuses on enhancement of soft
              skills of a child but also their academic needs and finances.
            </Text>
            <Text
              display={["none", "block"]}
              fontSize="19"
              textAlign="center"
              p={12}
              bgColor="gray.100"
              borderRadius={10}
            >
              UpChild Foundation™ is a Bengaluru based non-profit whose goal is
              to help underprivileged children lead a more comfortable city
              life. Our non-profit not only focuses on enhancement of soft
              skills of children but also their academic needs and finances.
              We target children in primary and high school belonging to 
              Government schools, hostels and orphanages in order to promote and
              develop their linguistic learning habits from an early age.
            </Text>
            </Flex>
          </Center>
        </Flex>
      </Flex>
    </div>
  );
}
