import { BellIcon, CloseIcon, HamburgerIcon, StarIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  Image,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import UpChildLogo from "../assets/UpChildLogo.svg";

const Logo = (props) => {
  return <Image src={UpChildLogo}></Image>;
};

export const Navbar = () => {
  // const { colorMode, toggleColorMode } = useColorMode();
  // const isDark = colorMode === "dark";
  const [display, changeDisplay] = useState("none");
  return (
    <Flex w="100%" boxShadow="lg" pos="fixed" bgColor="#edf2f7" zIndex="100">
      <Flex
        justify="flex-start"
        w={["60%", "60%", "20%", "20%"]}
        m={[2, 3, 4, 4]}
        ml={9}
        alignItems="center"
      >
        <Link to="/#">
          <Logo color={useColorModeValue("gray.700", "white")} />
        </Link>
      </Flex>
      <Flex
        position="relative"
        pr={5}
        align="center"
        // bgColor={["none","none","blue.50"]}
        w="100%"
        justify="flex-end"
      >
        {/* Desktop */}
        <Flex display={["none", "none", "flex", "flex"]}>
          <Link to="/#">
            <Button
              variant="ghost"
              aria-label="Home"
              my={5}
              w="100%"
              _focus={{ boxShadow: "none" }}
            >
              Home
            </Button>
          </Link>

          <Link to="/#about">
            <Button
              variant="ghost"
              aria-label="About"
              my={5}
              w="100%"
              _focus={{ boxShadow: "none" }}
            >
              About us
            </Button>
          </Link>

          <Link to="/our-team/#">
            <Button
              variant="ghost"
              aria-label="Programs"
              my={5}
              w="100%"
              _focus={{ boxShadow: "none" }}
            >
              Team
            </Button>
          </Link>

          <Link to="/what-we-do/#">
            <Button
              variant="ghost"
              aria-label="Programs"
              my={5}
              w="100%"
              _focus={{ boxShadow: "none" }}
            >
              What We Do
            </Button>
          </Link>

          <Link to="/#gallery">
            <Button
              variant="ghost"
              aria-label="Gallery"
              my={5}
              w="100%"
              _focus={{ boxShadow: "none" }}
            >
              Gallery
            </Button>
          </Link>

          {/* <Link to="/fund-raising/#">
            <Button
              variant="ghost"
              aria-label="About"
              my={5}
              w="100%"
              _focus={{ boxShadow: "none" }}
            >
              Donate
            </Button>
          </Link> */}

          <Link to="/#footer">
            <Button
              variant="ghost"
              aria-label="Contact"
              my={5}
              w="100%"
              _focus={{ boxShadow: "none" }}
            >
              Contact
            </Button>
          </Link>
        </Flex>

        <Link to="/fund-raising/">
          <Button
            leftIcon={<StarIcon />}
            colorScheme="teal"
            variant="solid"
            m={2}
            aria-label="Donate"
            _focus={{ boxShadow: "none" }}
          >
            Donate!
          </Button>
        </Link>

        {/* <Switch color="green" isChecked={isDark} onChange={toggleColorMode} m={5} /> */}
        {/* Mobile */}
        <IconButton
          aria-label="Open Menu"
          size="lg"
          m={2}
          icon={<HamburgerIcon />}
          onClick={() => changeDisplay("flex")}
          display={["flex", "flex", "none", "none"]}
        />
      </Flex>

      {/* Mobile Content */}

      <Flex
        w="100vw"
        display={display}
        bgColor="gray.50"
        zIndex={20}
        h="100vh"
        pos="fixed"
        top="0"
        left="0"
        overflowY="auto"
        flexDir="column"
      >
        <Flex justify="flex-end">
          <IconButton
            mt={2}
            mr={2}
            aria-label="Open Menu"
            size="lg"
            icon={<CloseIcon />}
            onClick={() => changeDisplay("none")}
          />
        </Flex>

        <Flex
          flexDir="column"
          onClick={() => changeDisplay("none")}
          align="center"
        >
          <Link to="/#">
            <Button
              variant="ghost"
              onClick={() => changeDisplay("none")}
              aria-label="Home"
              my={5}
              w="100%"
            >
              Home
            </Button>
          </Link>

          <Link to="/#about" onClick={() => changeDisplay("none")}>
            <Button variant="ghost" aria-label="About" my={5} w="100%">
              About us
            </Button>
          </Link>

          <Link to="/our-team/#">
            <Button
              variant="ghost"
              aria-label="Programs"
              my={5}
              w="100%"
              _focus={{ boxShadow: "none" }}
            >
              Team
            </Button>
          </Link>

          <Link to="/what-we-do/#">
            <Button
              variant="ghost"
              onClick={() => changeDisplay("none")}
              aria-label="Programs"
              my={5}
              w="100%"
            >
              What We Do
            </Button>
          </Link>

          <Link to="/#gallery">
            <Button
              variant="ghost"
              onClick={() => changeDisplay("none")}
              aria-label="Gallery"
              my={5}
              w="100%"
            >
              Gallery
            </Button>
          </Link>

          {/* <Link to="/fund-raising/#">
            <Button
              variant="ghost"
              aria-label="About"
              my={5}
              w="100%"
              _focus={{ boxShadow: "none" }}
            >
              Donate
            </Button>
          </Link> */}

          <Link to="/#footer">
            <Button
              variant="ghost"
              onClick={() => changeDisplay("none")}
              aria-label="Contact"
              my={5}
              w="100%"
            >
              Contact
            </Button>
          </Link>

          <Link to="/fund-raising/">
            <Button
              variant="ghost"
              onClick={() => changeDisplay("none")}
              aria-label="Donate"
              my={5}
              w="100%"
            >
              Donate
            </Button>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  );
};
