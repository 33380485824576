import { Center, Heading } from "@chakra-ui/react";
export default function CustomHeading({ heading }) {
  return (
    <Center>
      <Heading
        fontWeight="extrabold"
        color={"#10454f"}
        fontSize={["2xl", "3xl"]}
        pb="1rem"
        pt="1.5rem"
      >
        {heading}
      </Heading>
    </Center>
  );
}
