import {
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import VolunteeringGraphic from "../assets/become_a_volunteer.svg";
import openRegistrationForm from "../utils/utility";
export default function Volunteering() {
  return (
    <div>
      <Flex id="get-involved" w="full" className="anchor-volunteer"></Flex>
      <Flex w="full" minHeight={"85vh"} mt={"10vh"} direction={"column"}>
        <VStack
          minHeight="85vh"
          px={[12, 12, 28]}
          py="5"
          pb="4vh"
          backgroundColor="#D6ECB7"
          justifyContent="start"
          id="volunteer"
        >
          <Heading
            fontSize={["3xl", "4xl"]}
            fontWeight="extrabold"
            mt="3rem "
            color={"#10454f"}
          >
            GET INVOLVED!
          </Heading>
          <Spacer />
          <HStack alignItems={["start", "start", "center"]}>
            <VStack
              pr={[0, 0, "4rem"]}
              alignItems={["center", "center", "start"]}
            >
              <Image
                width={["100%", "80%", 0]}
                p={["1rem", "2rem", 0]}
                src={VolunteeringGraphic}
              ></Image>

              <Text
                fontSize={["medium", "medium", "2xl"]}
                // pb="0.2rem"
                color="gray.700"
                fontWeight="bold"
              >
                Looking for a way to earn activity points?
              </Text>
              <Text
                fontSize={["medium", "medium", "xl"]}
                pb="0.8rem"
                color="gray.700"
                fontWeight="semibold"
              >
                Here's an exclusive opportunity for you to volunteer with us...
              </Text>
              <UnorderedList pb="3rem">
                <ListItem
                  ml="5"
                  mb="1"
                  fontSize={["medium", "medium", "lg"]}
                  color="gray.700"
                >
                  Participate {"&"} earn points as a volunteer
                </ListItem>
                <ListItem
                  ml="5"
                  mb="1"
                  fontSize={["medium", "medium", "lg"]}
                  color="gray.700"
                >
                  Work alongside your peers in giving back to the society
                </ListItem>
                <ListItem
                  ml="5"
                  mb="1"
                  fontSize={["medium", "medium", "lg"]}
                  color="gray.700"
                >
                  Build your profile for the 100-point activity
                </ListItem>
              </UnorderedList>
              <Button
                m="2rem"
                alignSelf={["center", "center", "start"]}
                size="lg"
                boxSizing="content-box"
                p="0.5rem 3rem"
                _expanded="false"
                backgroundColor="#10454F"
                color="white"
                _hover={{ backgroundColor: "#8DC63F" }}
                onClick={openRegistrationForm}
              >
                <Text>REGISTER NOW</Text>
              </Button>
              {/* <Text
                fontSize={["x-small", "x-small", 0]}
                color="gray.600"
                pt="2"
              >
                * You must be a student of Ramaiah Institute of Technology to apply.
              </Text> */}
            </VStack>
            <VStack
              direction="column"
              alignContent="center"
              justifyContent="center"
              maxWidth={[0, 0, "40%"]}
            >
              <Image
                //   width="30%"
                src={VolunteeringGraphic}
              ></Image>
              {/* <Text fontSize={[0, 0, "smaller"]} color="gray.600" pt="4">
              * You must be a student of Ramaiah Institute of Technology to apply.
              </Text> */}
            </VStack>
          </HStack>
          <Spacer />
        </VStack>
      </Flex>
    </div>
  );
}
