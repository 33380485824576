import React from "react";
import {
  Image,
  Box,
  Heading,
  Text,
  Flex,
  color,
  border,
  Center,
} from "@chakra-ui/react";
import CustomHeading from "../CustomHeading";
import { TeamDetails } from "./teamDetails";

function Feature({ title, desc, img, ...rest }) {
  return (
    <Box
      p={5}
      shadow="md"
      borderWidth="1px"
      {...rest}
      margin="10px 10px 10px 10px"
      border={"4px solid #8DC63F"}
    >
      <Image src={img} boxSize="200px" objectFit="cointain"></Image>
      <Heading fontSize="xl">{title}</Heading>
      <Text mt={4}>{desc}</Text>
    </Box>
  );
}

function CreateCards(props) {
  return <Feature img={props.path} title={props.name} desc={props.desig} />;
}

const TeamMembers = (
  <Flex mt={-20} mb={40}>
    <Box width={"20%"}></Box>
    <Flex
      spacing={8}
      direction="row"
      paddingTop={"150px"}
      wrap="wrap"
      align={"center"}
      justify="center"
    >
      {TeamDetails.map((props) => CreateCards(props))}
    </Flex>
    <Box width={"20%"}></Box>
  </Flex>
);

export default function Error() {
  return TeamMembers;
}
