import React from "react";
import ProgramCard from "../components/WhatWeDo/ProgramCard";
import CustomHeading from "../components/CustomHeading";
import { ProgramItems } from "../components/WhatWeDo/ProgramItems";
import { Heading, Box, Center } from "@chakra-ui/react";
import UpChildFooter from "../components/CustomFooter";

const WhatWeDo = () => {
  return (
    <div style={{ overflowX: "clip" }}>
      <Box w="100%" pt="20vh"></Box>
      <Center>
        <Heading textAlign="end" color={"#10454f"} fontWeight="extrabold">
          What We Do
        </Heading>
      </Center>
      {ProgramItems.map(ProgramCard)}
      <UpChildFooter />
    </div>
  );
};
export default WhatWeDo;
