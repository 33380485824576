import React from "react";
// import CustomHeading from "../components/CustomHeading";
import { Heading, Box, Center, Container } from "@chakra-ui/react";
import UpChildFooter from "../components/CustomFooter";

const CancelandRefund = () => {
  return (
    <div>
      <div style={{ overflowX: "clip" }}>
        <Box w="100%" pt="15vh"></Box>
        <Center>
          <Heading textAlign="end" color={"#10454f"} fontWeight="extrabold">
          Cancellation & Refund Policy
          </Heading>
        </Center>
        <Container
          align="justify"
          maxW="3xl"
          centerContent
          bg="white"
          w="100%"
          p={4}
          borderRadius="10px"
          borederwidth="1px"
        >
          {/* <Heading
            as="h3"
            size="sm"
            textAlign="end"
            color={"#10454f"}
            fontWeight="bold"
            p="10px"
          >
            Use of the website
          </Heading> */}
            UPCHILD FOUNDATION does not entertain any cancellations or refunds.
            For more queries, please email us on foundationupchild@gmail.com
        </Container>
        <UpChildFooter />
      </div>
    </div>
  );
};
export default CancelandRefund;
