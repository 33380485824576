import {
  Flex,
  HStack,
  Image,
  ListItem,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";
import CustomHeading from "../CustomHeading";

function ProgramSubSubPoints(props) {
  return (
    <UnorderedList>
      <ListItem
        ml="5"
        fontSize={["sm", "sm", "medium"]}
        color="gray.700"
        fontWeight="normal"
        mt={"1"}
      >
        {props}
      </ListItem>
    </UnorderedList>
  );
}

function ProgramSubPoints(props) {
  return (
    <UnorderedList>
      <ListItem
        ml="5"
        mb="1vh"
        fontSize={["sm", "sm", "medium"]}
        textColor={"#10454f"}
        fontWeight="semibold"
      >
        {props.title}
        {props.info.map(ProgramSubSubPoints)}
      </ListItem>
    </UnorderedList>
  );
}

export default function ProgramCard(props) {
  return (
    <div>
      <Flex w="full" direction="column" px={"3vw"} py={"2vh"} pb={"4vh"}>
        <VStack px={[10, 10, 25]} py="5" pb="4vh" justifyContent="start">
          <HStack alignItems={["start", "start", "center"]}>
            <VStack
              pr={[0, 0, "4rem"]}
              alignItems={["center", "center", "start"]}
              width={["full", "full", "60vw"]}
            >
              <Image
                width={["80%", "70%", 0]}
                p={["1rem", "2rem", 0]}
                src={props.image}
              ></Image>

              <CustomHeading heading={props.title}></CustomHeading>

              <Text
                fontSize={["medium", "medium", "l"]}
                pb="0.8rem"
                color="gray.700"
                fontWeight="semibold"
              >
                {props.summary}
              </Text>

              {props.subpoints.map(ProgramSubPoints)}
            </VStack>

            <VStack
              direction="column"
              alignContent="center"
              justifyContent="center"
              width={[0, 0, "30vw"]}
            >
              <Image
                boxSize={"25vw"}
                objectFit="contain"
                src={props.image}
              ></Image>
            </VStack>
          </HStack>
        </VStack>
      </Flex>
    </div>
  );
}
