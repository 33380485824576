import React from "react";
import UpChildFooter from "../components/CustomFooter";
import FundraisingCard from '../components/FundraisingCard'

const Fundraising = () =>
{
    return (
        <div>
            <FundraisingCard />
            <UpChildFooter />
        </div>
    );
};
export default Fundraising;
