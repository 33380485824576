import Carousel from "../components/Carousel";
import Collaboration from "../components/Collaboration";
import UpChildFooter from "../components/CustomFooter";
import LandingBanner from "../components/LandingBanner";
import LandingBannerMobile from "../components/LandingBannerMobile";
import OurMission from "../components/OurMission";
import Volunteering from "../components/Volunteering";
import WhoAreWe from "../components/WhoAreWe";
import Team from "../components/Team/Team";

export default function Home() {
  return (
    <div style={{ overflowX: "clip" }}>
      <LandingBanner />
      <LandingBannerMobile />
      <WhoAreWe />
      <Carousel />
      <OurMission />
      <Collaboration />
      <Volunteering />
      <UpChildFooter />
    </div>
  );
}
