import TeamMembers from "../components/Team/Team";
import CustomHeading from "../components/CustomHeading";
import UpChildFooter from "../components/CustomFooter";

import { Center } from "@chakra-ui/react";

const myItem = (
  <div>
    <Center pt={40}>
      <CustomHeading heading="Our Team"></CustomHeading>
    </Center>
    <TeamMembers />
    <UpChildFooter />
  </div>
);

export default function Error() {
  return myItem;
}
