import {
  Box,
  Container,
  Flex,
  Image,
  SimpleGrid,
  Stack,
  Text,
  Link,
  useColorModeValue,
} from "@chakra-ui/react";
import { HashLink as LinkHash } from "react-router-hash-link";
import UpChildLogo from "../assets/UpChildLogo.svg";
const Logo = (props) => {
  return <Image height="5vh" src={UpChildLogo}></Image>;
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

function UpChildFooter() {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      id="footer"
    >
      <Container maxWidth="100hw" py={10} px={[5, 20]}>
        <Flex
          flexDirection={["column", "column", "row"]}
          justifyContent="space-between"
        >
          <Stack spacing={6}>
            <Box>
              <Logo color={useColorModeValue("gray.700", "white")} />
            </Box>
            <Text fontSize={"sm"}>
              Registered under Section 8 of Companies act, 2013
            </Text>
            <Text
              fontSize={[0, 0, "sm"]}
              fontWeight="medium"
              fontStyle="italic"
              // display={["none", "none", "block"]}
            >
            </Text>
          </Stack>

          <SimpleGrid
            templateColumns={{ sm: "1fr", md: " 1fr  1fr" }}
            spacing={6}
          >
            <Stack align={"flex-start"}>
              <ListHeader>About Us</ListHeader>
              {/* <LinkHash href={"#"}>The Team</LinkHash> */}
              <LinkHash to={"/#mission"}>Mission</LinkHash>
              <LinkHash to={"/#collaborators"}>Partners</LinkHash>
              <LinkHash to={"/what-we-do/#"}>What we do</LinkHash>
              {/* <LinkHash href={"/privacy-policy/"}>Privacy Policy</LinkHash> */}
            </Stack>
            
                <Stack align={"flex-start"}>
                  <ListHeader>Support</ListHeader>

                  <LinkHash to={"/privacy-policy"}>Privacy Policy</LinkHash>

                  {/* <Link
                    href={
                      "https://merchant.razorpay.com/policy/KdbXWJMMmDwCvY/privacy"
                    }
                  >
                    Privacy Policy
                  </Link> */}

                  <LinkHash to={"/terms-and-conditions"}>Terms and Conditions</LinkHash>

                  {/* <Link
                    href={
                      "https://merchant.razorpay.com/policy/KdbXWJMMmDwCvY/terms"
                    }
                  >
                    Terms and Conditions
                  </Link> */}

                  <LinkHash to={"/cancel-and-refund"}>Cancellation & Refund Policy</LinkHash>

                  {/* <Link
                    href={
                      "https://merchant.razorpay.com/policy/KdbXWJMMmDwCvY/refund"
                    }
                  >
                    Cancellation & Refund Policy
                  </Link> */}

                  <LinkHash to={"/contact-us"}>Contact Us</LinkHash>

                  {/* <Link
                    href={
                      "https://merchant.razorpay.com/policy/KdbXWJMMmDwCvY/contact_us"
                    }
                  >
                    Contact Us
                  </Link> */}

                  <LinkHash to={"/donation-and-ship"}>Donation and Shipping Policy</LinkHash>
                  {/* <Link
                    href={
                      "https://merchant.razorpay.com/policy/KdbXWJMMmDwCvY/shipping"
                    }
                  >
                    Shipping and Delivery Policy
                  </Link> */}
                </Stack>
      
            <Stack align={"flex-start"}>
              <ListHeader>Contact Us</ListHeader>
              <Link href={"mailto:foundationupchild@gmail.com"}>Email</Link>
              {/* <LinkHash href={"#"}>Twitter</LinkHash> */}
              <Link href={"https://www.instagram.com/upchildfoundation/"}>
                Instagram
              </Link>
              <Link
                href={"https://www.linkedin.com/company/upchild-foundation/"}
              >
                LinkedIn
              </Link>
            </Stack>
          </SimpleGrid>
        </Flex>
        <Text
          fontSize="sm"
          fontWeight="semibold"
          fontStyle="italic"
          pt="2rem"
          display={["block", "block", "none"]}
        ></Text>
      </Container>
    </Box>
  );
}

export default UpChildFooter;
