export const TeamDetails = [
  {
    path: require("../../assets/team/KushalKrishna.webp"),
    name: "Kushal Krishna",
    desig: "CEO",
  },
  {
    path: require("../../assets/team/VibhutiGupta.webp"),
    name: "Vibhuti Gupta",
    desig: "COO",
  },
  {
    path: require("../../assets/team/default.webp"),
    name: "Deepa",
    desig: "CMO",
  },
  {
    path: require("../../assets/team/DivakarSharma.webp"),
    name: "Divakar Sharma",
    desig: "CPO",
  },

  {
    path: require("../../assets/team/default.webp"),
    name: "Tamanna",
    desig: "Head of Fundraising",
  },
  {
    path: require("../../assets/team/default.webp"),
    name: "Rishith",
    desig: "CHRO",
  },
  {
    path: require("../../assets/team/IshaanShetty.webp"),
    name: "Ishaan Shetty",
    desig: "Partnership Manager",
  },

  {
    path: require("../../assets/team/NiveditaGS.webp"),
    name: "Nivedita G S",
    desig: "Head of donations",
  },

  {
    path: require("../../assets/team/ZeeshanAhmad.webp"),
    name: "Zeeshan Ahmad",
    desig: "Partnership Manager",
  },
  {
    path: require("../../assets/team/ManishVenugopal.png"),
    name: "Manish Venugopal",
    desig: "Partnership Manager",
  },
];
