import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import { Navbar } from "./components/Navbar";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsAndConditions from "./pages/T&C";
import WhatWeDo from "./pages/WhatWeDo";
import Team from "./pages/About";
import Login from "./pages/Login"
import Error from "./pages/Error404";
import Fundraising from './pages/Fundraising'
import { useCookies } from "react-cookie";
import CancelandRefund from "./pages/cancel";
import DonationAndShip from "./pages/donation&ship";
import ContactUs from "./pages/Contact";
const colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },
};

const theme = extendTheme({ colors });

function App() {

    const [cookies] = useCookies(["user"]);

  return (
    <ChakraProvider theme={theme}>
      <Router>
      <Navbar />
      <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/what-we-do" element={<WhatWeDo/>} />
          <Route exact path="/terms-and-conditions" element={<TermsAndConditions/>}/>
          <Route exact path="/cancel-and-refund" element={<CancelandRefund/>}/>
          <Route exact path="/donation-and-ship" element={<DonationAndShip/>}/>
          <Route exact path="/contact-us" element={<ContactUs/>}/>
          <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
          <Route exact path="/login" element={!cookies["user"] ? <Login /> : <Team />}  />
          <Route exact path="/fund-raising" element={<Fundraising />}  />
          <Route path="*" element={<Error/>}/>
          <Route path="/our-team" element={<Team/>}/>
      </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
