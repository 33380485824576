import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAjq5784WCMroxADGCvNnbikCbRi7u4cek",
    authDomain: "backend-login-c547b.firebaseapp.com",
    projectId: "backend-login-c547b",
    storageBucket: "backend-login-c547b.appspot.com",
    messagingSenderId: "158793728958",
    appId: "1:158793728958:web:ea6b8a0048b60b26a4afef",
    measurementId: "G-9GF4B0SB11"
  };

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { auth, db };