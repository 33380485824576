import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CustomHeading from "./CustomHeading";

export default function Carousel() {
  const settings = {
    autoplay: true,
    autoplaySpeed: 2500,
    dots: true,
    infinite: true,
    mobileFirst: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <Image src="/arrow.png" boxSize="25px" zIndex="1" />,
    prevArrow: <Image src="/arrow1.png" boxSize="25px" ml="-1" zIndex="1" />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Flex id="gallery" w="full" className="anchor"></Flex>
      <Flex w="full" minHeight={"30vh"} mt={"10vh"} direction={"column"}>
        <CustomHeading heading={"Our Impact"}></CustomHeading>
        <Box pb={15} ml={10} mr={10}>
          <Slider {...settings}>
            <Box p="2">
              <Image src="/1.jpg" borderRadius="6" alt="Teaching Kids at NGO" pointerEvents= "none"/>
            </Box>
            <Box p="2">
              <Image src="/2.jpg" borderRadius="6" alt="Teaching Kids at NGO" pointerEvents= "none"/>
            </Box>
            <Box p="2">
              <Image src="/3.jpeg" borderRadius="6" alt="Teaching Kids at NGO" pointerEvents= "none"/>
            </Box>
            <Box p="2">
              <Image src="/4.jpg" borderRadius="6" alt="Teaching Kids at NGO" pointerEvents= "none"/>
            </Box>
            <Box p="2">
              <Image src="/5.jpg" borderRadius="6" alt="Teaching Kids at NGO" pointerEvents= "none"/>
            </Box>
            <Box p="2">
              <Image src="/6.jpg" borderRadius="6" alt="Teaching Kids at NGO" pointerEvents= "none"/>
            </Box>
          </Slider>
        </Box>
      </Flex>
    </div>
  );
}
