import { Box, Center, Image } from "@chakra-ui/react";

export default function MissionCard({ title, imgSrc }) {
  return (
    <Center>
      <Box w="sm" p="5" overflow="hidden">
        <Center>
          <Image src={imgSrc} alt={title} boxSize="110px" />
        </Center>

        <Box
          mt="1"
          fontWeight="bold"
          as="h2"
          fontSize="1rem"
          textAlign="center"
          isTruncated
        >
          {title}
        </Box>
      </Box>
    </Center>
  );
}
