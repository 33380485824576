import React from "react";
// import CustomHeading from "../components/CustomHeading";
import { Heading, Box, Center, Container } from "@chakra-ui/react";
import UpChildFooter from "../components/CustomFooter";

const DonationAndShip = () => {
  return (
    <div>
      <div style={{ overflowX: "clip" }}>
        <Box w="100%" pt="15vh"></Box>
        <Center>
          <Heading textAlign="end" color={"#10454f"} fontWeight="extrabold">
          Donation and Shipping Policy
          </Heading>
        </Center>
        <Container
          align="justify"
          maxW="3xl"
          centerContent
          bg="white"
          w="100%"
          p={4}
          borderRadius="10px"
          borederwidth="1px"
        >
          {/* <Heading
            as="h3"
            size="sm"
            textAlign="end"
            color={"#10454f"}
            fontWeight="bold"
            p="10px"
          >
            Use of the website
          </Heading> */}
            This policy is not applicable to UPCHILD FOUNDATION, as we are not a commercial
            organisation and do not make any online sale and seek only your online donations
            to provide better access to education, health care and livelihood opportunities
            to the poor population. However, within 30 days of donation, we will send you the
            formal receipt, provided the email address provided by you is correct and valid.<br/><br/>
            UPCHILD FOUNDATION is proud to use Razorpay Payment Gateway Name for fast, easy
            and efficient secure payments. All major credit cards are accepted. For any queries,
            kindly contact us on foundationupchild@gmail.com
        </Container>
        <UpChildFooter />
      </div>
    </div>
  );
};
export default DonationAndShip;
