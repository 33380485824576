import { Box, Flex, Image, SimpleGrid } from "@chakra-ui/react";
import CustomHeading from "./CustomHeading";
import GHPS from '../assets/collaborations/GHPS.jpg';
import rainbowHome from '../assets/collaborations/rainbow.jpg';
import aksharamale from '../assets/collaborations/g17.jpg';

export default function Collaboration() {
  return (
    <div id="collaborators">
      <Flex w="full" minHeight={"30vh"} mt={"10vh"} direction={"column"}>
        <CustomHeading heading={"Our Collaborations"}></CustomHeading>
        <Flex direction="column" justifyContent="center">
          <Flex direction="row" justifyContent="center" m={6}>
            <SimpleGrid columns={[1, null, 3]}>
              <Box
                maxW="xs"
                borderWidth="1.5px"
                borderRadius="lg"
                overflow="hidden"
                m={5}
              >
                <Image
                  //src="https://i0.wp.com/needbaseindia.org/wp-content/uploads/2015/12/Home-1-min-scaled.jpg"
                  src={rainbowHome}
                  h="70%"
                  w="100%"
                  objectFit="cover"
                  pointerEvents= "none"
                />
                <Box p="2" ml="2" mb="2">
                  <Box
                    mt="1"
                    fontWeight="semibold"
                    as="h4"
                    lineHeight="tight"
                    isTruncated
                  >
                    Need Base India - Rainbow Home
                  </Box>

                  <Box as="span" color="gray.600" fontSize="sm">
                    Jalahalli
                  </Box>
                </Box>
              </Box>

              <Box
                maxW="xs"
                borderWidth="1.5px"
                borderRadius="lg"
                overflow="hidden"
                m={5}
              >
                <Image
                  src={aksharamale}
                  h="70%"
                  w="100%"
                  objectFit="cover"
                  pointerEvents= "none"
                />
                <Box p="2" ml="2" mb="2">
                  <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight">
                    Aksharamale Education and Charitable Foundation
                  </Box>

                  <Box as="span" color="gray.600" fontSize="sm">
                    Yelahanka
                  </Box>
                </Box>
              </Box>

              <Box
                maxW="xs"
                borderWidth="1.5px"
                borderRadius="lg"
                overflow="hidden"
                m={5}
              >
                <Image
                  src={GHPS}
                  h="70%"
                  w="100%"
                  objectFit='cover'
                  pointerEvents= "none"
                />
                <Box p="2" ml="2" mb="2">
                  <Box mt="1" fontWeight="semibold" as="h4" lineHeight="tight">
                    Government Higher Primary School
                  </Box>

                  <Box as="span" color="gray.600" fontSize="sm">
                    Poornapura
                  </Box>
                </Box>
              </Box>
            </SimpleGrid>
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}
