import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import LandingImage1 from "../assets/landing_image1.jpg";
import { HashLink as Link } from "react-router-hash-link";

export default function LandingBannerMobile() {
  return (
    <Box
      pt="80px"
      pb="10px"
      // width="120hw"
      minHeight="85vh"
      display={["block", "block", "none"]}
      backgroundImage={LandingImage1}
      bgRepeat="no-repeat"
      // backgroundImage="rgba(0, 0, 0, 0.5)"
      backgroundPosition="45% 35%"
    >
      <VStack pt="35vh">
        <Heading fontSize="2xl" textAlign="center" color="white">
          Helping children realise <br />
          their dreams
        </Heading>
        <Text textAlign="center" pt="1rem" pb="2rem" px="2rem" color="white">
          Delivering <b>linguistic training</b> along with career counselling
          and financial support to underprivileged children, in efforts to
          uplift them.{" "}
        </Text>

        <Link to="/what-we-do/#">
          <Button
            mb={7}
            size="lg"
            boxSizing="content-box"
            p="0.5rem 3rem"
            _expanded="false"
            backgroundColor="#8DC63F"
            color="white"
            _hover={{ backgroundColor: "#10454F" }}
          
          >
            Read More
          </Button>
        </Link>
      </VStack>
    </Box>
  );
}
