import {
  Button,
  Flex,
  Heading,
  HStack,
  Image,
  ListItem,
  Spacer,
  Text,
  UnorderedList,
  VStack,
  SimpleGrid,
} from "@chakra-ui/react";
import DonateGraphic from "../assets/donate.svg";
import { useState, useEffect } from "react";

export default function FundrainingCard() {
  useEffect(() => {
    const rzpPaymentForm = document.getElementById("rzp_payment_form");

    if (!rzpPaymentForm.hasChildNodes()) {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = "pl_KdB4Txi05S8Nig";
      rzpPaymentForm.appendChild(script);
    }
  });

  const Contributions = () => {
    return (
      <UnorderedList pb="3rem">
        <ListItem
          ml="5"
          mb="1"
          fontSize={["medium", "medium", "lg"]}
          color="gray.700"
        >
          Around 80-90% of the funds will be used to directly provide for
          further education of the students and for development of the schools
          we work in.
        </ListItem>
        <ListItem
          ml="5"
          mb="1"
          fontSize={["medium", "medium", "lg"]}
          color="gray.700"
        >
          The rest of the funds will help us expand to more schools across
          Bengaluru.
        </ListItem>
      </UnorderedList>
    );
  };

  const Impact = () => {
    return (
      <UnorderedList pb="3rem">
        <ListItem
          ml="5"
          mb="1"
          fontSize={["medium", "medium", "lg"]}
          color="gray.700"
        >
          The most talented children in the places we work compromise their
          education to indulge in activities where they can earn money and
          support their families.
        </ListItem>
        <ListItem
          ml="5"
          mb="1"
          fontSize={["medium", "medium", "lg"]}
          color="gray.700"
        >
          We work together with our donors to help these children focus on their
          education while we provide for their well being.
        </ListItem>
        <ListItem
          ml="5"
          mb="1"
          fontSize={["medium", "medium", "lg"]}
          color="gray.700"
        >
          This helps us in not only alleviating their immediate quality of life,
          but also help their families and children become sustainable in the
          future
        </ListItem>
      </UnorderedList>
    );
  };

  return (
    <div>
      <Flex id="get-involved" w="full" className="anchor-volunteer"></Flex>
      <Flex w="full" minHeight={"85vh"} direction={"column"}>
        <VStack
          minHeight="85vh"
          px={[12, 12, 28]}
          py="5"
          pb="4vh"
          backgroundColor="#D6ECB7"
          justifyContent="start"
          id="volunteer"
        >
          <Heading
            fontSize={["3xl", "4xl"]}
            fontWeight="extrabold"
            mt="5rem"
            color={"#10454f"}
          >
            Donate Now!
          </Heading>
          <Spacer />

          <SimpleGrid columns={[1, 1, 1, 2]} spacing="40px">
            <VStack
              pr={[0, 0, "4rem"]}
              alignItems={["center", "center", "center"]}
            >
              <Text
                fontSize={["medium", "medium", "2xl"]}
                // pb="0.2rem"
                color="gray.700"
                fontWeight="bold"
              >
                Our journey so far
              </Text>
              <Text
                fontSize={["medium", "medium", "xl"]}
                pb="0.8rem"
                color="gray.700"
                fontWeight="semibold"
              >
                We started off by teaching seven kids in an orphanage and since
                then we have expanded to around 200 volunteers impacting over
                700 children in various Government schools and orphanages across
                Bengaluru.
              </Text>
              <UnorderedList pb="3rem">
                <ListItem
                  ml="5"
                  mb="1"
                  fontSize={["medium", "medium", "lg"]}
                  color="gray.700"
                  fontWeight="bold"
                >
                  How will your contribution help us?
                </ListItem>

                <Contributions />

                <ListItem
                  ml="5"
                  mb="1"
                  fontSize={["medium", "medium", "lg"]}
                  color="gray.700"
                  fontWeight="bold"
                >
                  How large is the impact of your contribution on these
                  children?
                </ListItem>
                <Impact />
              </UnorderedList>
            </VStack>

            <VStack
              textAlign="justify"
              justifyContent={["", "", "center"]}
              maxWidth={["100%", "100%", "100%"]}
              padding={[0, 0, 0]}
              minWidth={[0, 0, "300px"]}
            >
              <Flex>
                <form id="rzp_payment_form" style={{scale: "1.3"}}></form>
              </Flex>
              <Image
                pt={7}
                w={["60%", "40%", "40%"]}
                src={DonateGraphic}
              ></Image>
              <Text
                fontSize={["medium", "medium", "xl"]}
                pt={10}
                mr={5}
                color="gray.700"
                fontWeight="bold"
              >
                How can you track your contributions?
              </Text>
              <Text
                fontSize={["medium", "medium", "lg"]}
                color="gray.600"
                pt="2"
              >
                We issue a yearly newsletter which exhibits where we spent all
                the funds we received in the respective financial year.
              </Text>
            </VStack>

            <Text>*Your donations are tax exempted under 80G of the Indian Income Tax Act</Text>
          </SimpleGrid>
          <Spacer />
        </VStack>
      </Flex>
    </div>
  );
}
