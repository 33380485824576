export const ProgramItems = [
  {
    title: "Linguistic Training",
    summary: `We provide linguistic training in order to improve phonetic 
                    skills (English) of students in government high-schools 
                    (or orphanages and hostels).
                    Our training includes:`,
    image: "/p1.png",
    subpoints: [
      {
        title: "Providing spoken and written English classes",
        info: [
          `Our curriculum is designed in a way which covers the three
                essential parts for learning English.`,

          `The first part includes basic grammar which will enable
                students to form their sentences properly and also understand
                the need to use a particular word instead of the other`,

          `The second part includes spoken English skills which will make
                the students more confident while communicating with their
                peers.`,

          `The third part includes writing skills which includes letter
                writing, report making, argumentative writing, etc.`,
        ],
      },
      {
        title: "Assigning volunteers to the class",
        info: [
          `Each class will be assigned a minimum of 2 volunteers who are
                well versed in Kannada.`,

          `These volunteers are responsible for teaching.`,

          `The volunteers will be considering the performance of each
                student in the respective class and will prepare a student report
                based on the parameters set by our company.`,

          `Through the student report, a plan will be created to help the
                slow learners catch up with the class.`,

          `This plan will be student-friendly where they do not feel any
                sort of pressure.`,

          `Students will be selected for scholarship consideration based on
                the student report.`,

          `The volunteers will take up the role of mentors for students in
                their respective class.`,
        ],
      },
    ],
  },

  {
    title: "Career Counselling",
    summary: `We also provide career counselling to the needy students in secondary and
                senior secondary schools, so that they are aware of the choices they have
                with respect to their careers. \n \n 
                Our counselling includes:`,
    image: "/p2.png",
    subpoints: [
      {
        title: "Arrangement of sessions",
        info: [
          `Sessions will be organised after the commencement of
                Linguistic classes.`,

          `These sessions will be detailed, opening up various career
                choices to the students.`,

          `There will be a minimum of 2 sessions conducted per class.`,

          `The target grades range from classes 9th to 12th.`,
        ],
      },
      {
        title: "Discussion with the expert",
        info: [
          `A few sessions will be conducted by industry experts.`,

          `These sessions will provide an insight about various career
                choices to the students.`,
        ],
      },
    ],
  },

  {
    title: "Providing Financial Support",
    summary: `Our company will provide funding to the aforementioned students’ academic
                needs and provide textbooks for linguistic training. \n \n 
                This support includes:`,
    image: "/p3.png",
    subpoints: [
      {
        title: "Scholarship",
        info: [
          `Mentors assigned to each student will be providing us with the
                reports regarding their performance.`,

          `Scholarships will be given to the selected students based on
                these reports to help them with their higher education.`,

          `These scholarships will be provided from the donations
                received through our portal.`,
        ],
      },
      {
        title: "Textbooks",
        info: [
          `Textbooks customised to the needs of the schools will be
                provided to the students.`,

          `The students selected for funding will be given textbooks for
                subjects they wish to pursue after attending our counselling
                sessions.`,
        ],
      },
    ],
  },
];
