import { Center, Flex, SimpleGrid } from "@chakra-ui/react";
import CustomHeading from "./CustomHeading";
import MissionCard from "./MissionCard";
export default function OurMission() {
  return (
    <div id="mission">
      <Flex
        w="full"
        minHeight={"30vh"}
        mt={"10vh"}
        direction={"column"}
        background="#E7FFE5"
        pb="4vh"
      >
        <Center>
          <Flex direction="row">
            <Flex direction="column" justifyContent="center">
              <Center>
                <CustomHeading heading="Our Mission"></CustomHeading>
              </Center>

              <SimpleGrid columns={[2, null, 4]} mt="5">
                <MissionCard
                  title="Career Counselling"
                  imgSrc={"/careerC.png"}
                ></MissionCard>
                <MissionCard
                  title="Linguistic Training"
                  imgSrc={"/language.png"}
                ></MissionCard>
                <MissionCard
                  title="Financial Support"
                  imgSrc={"/financialOG.png"}
                ></MissionCard>
                <MissionCard
                  title="Sponsoring Books"
                  imgSrc={"/textbooks.png"}
                ></MissionCard>
              </SimpleGrid>
            </Flex>
          </Flex>
        </Center>
      </Flex>
    </div>
  );
}
