import React from "react";
// import CustomHeading from "../components/CustomHeading";
import { Heading, Box, Center, Container } from "@chakra-ui/react";
import UpChildFooter from "../components/CustomFooter";

const ContactUs = () => {
  return (
    <div>
      <div style={{ overflowX: "clip" }}>
        <Box w="100%" pt="15vh"></Box>
        <Center>
          <Heading textAlign="end" color={"#10454f"} fontWeight="extrabold">
          Contact Us
          </Heading>
        </Center>
        <Container
          align="justify"
          maxW="3xl"
          centerContent
          bg="white"
          w="100%"
          p={4}
          borderRadius="10px"
          borederwidth="1px"
        >
          {/* <Heading
            as="h3"
            size="sm"
            textAlign="end"
            color={"#10454f"}
            fontWeight="bold"
            p="10px"
          >
            Use of the website
          </Heading> */}
            Address: No 22, 6th Cross Road, Ramamurthy Nagar, Bangalore, Karnataka, India - 560016<br/><br/>
            Phone: +919901155574<br/><br/>
            Email: foundationupchild@gmail.com<br/><br/>
        </Container>
        <UpChildFooter />
      </div>
    </div>
  );
};
export default ContactUs;
