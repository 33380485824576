import React from "react";
// import CustomHeading from "../components/CustomHeading";
import { Heading, Box, Center,Container, OrderedList, ListItem ,Text} from "@chakra-ui/react";
import UpChildFooter from "../components/CustomFooter";

const PrivacyPolicy = () => {
  return (
    <div style={{ overflowX: "clip" }}>
      <Box w="100%" pt="15vh"></Box>
      <Center>
        <Heading textAlign="end" color={"#10454f"} fontWeight="extrabold">
          Privacy Policy
        </Heading>
      </Center>
    <Container align="justify" maxW="3xl" centerContent bg="white" w="100%" p={4} borderRadius="10px" borederwidth="1px" >

        <Heading as='h3' size='sm' textAlign="left" textColor={"#319795"} fontWeight="bold" p="10px" >
          Information Gathering
        </Heading>
        <OrderedList>
            <ListItem fontWeight="500">
                UPCHILD FOUNDATION collects information from the user in the following ways :</ListItem>
                <OrderedList styleType="lower-alpha" pl="30px" fontWeight="500">
                    <ListItem> Making a donation</ListItem>
                    <ListItem> Registering as a volunteer/intern</ListItem>
                    <ListItem> Registering for updates</ListItem>
                </OrderedList>
            <ListItem fontWeight="500" pt="10px">While making a donation, the user is requested to enter the following personal
            information for us to ensure genuine donations :</ListItem>
                <OrderedList  styleType="lower-alpha" pl="30px" fontWeight="500">
                    <ListItem> Name</ListItem>
                    <ListItem> Email Address</ListItem>
                    <ListItem> Telephone number</ListItem>
                    <ListItem> Payment processing details</ListItem>
                    <ListItem> Any other such data as required</ListItem>
                </OrderedList>
            <ListItem  fontWeight="500" pt="10px">UPCHILD FOUNDATION does not collect or record the user’s personal information unless
            he/she chooses to provide it.</ListItem>
        </OrderedList>
        
        <Heading  as='h3' size='sm' textAlign="end" textColor={"#319795"} fontWeight="bold" p="20px" >
           Use of Personal Information
        </Heading>
        <OrderedList>
            <ListItem fontWeight="500">
                General browsing of UPCHILD FOUNDATION is anonymous and does not collect any
                personal information except time, date and ISP which is used for data analytics and
                statistics.
            </ListItem>
            <ListItem fontWeight="500">
                By signing up on various services, the user explicitly authorises us to collect information
                based on the user's usage. The information is used to help provide a better experience to
                the user and is used as per the user’s specified instructions.
            </ListItem>
            <ListItem fontWeight="500">
                UPCHILD FOUNDATION keeps all the user data confidential and with utmost safety. The
                relevant information is only accessed by the internal and/or higher authorities. It is never
                shared with third-party individuals.
            </ListItem>
            <ListItem fontWeight="500">
                The personal user information given to UPCHILD FOUNDATION is also used for :
                <OrderedList styleType="lower-alpha">
                    <ListItem>Tracking and processing the donations</ListItem>
                    <ListItem>Sending emails , features, promotional material, surveys, brochures, catalogues,
                    reminders for donations, regular updates on the utilisation of donations by
                    UPCHILD FOUNDATION and other updates.</ListItem>
                    <ListItem>To find out more about the people who are visiting the website, donating, or
                    joining its campaigns.</ListItem>
                </OrderedList>
            </ListItem>
            <ListItem fontWeight="500">
                The user can anytime opt to delete all the information he/she has provided via email. The
                information will be deleted within two working days.
            </ListItem>
        </OrderedList> 

        <Heading  as='h3' size='sm' textAlign="end" textColor={"#319795"} fontWeight="bold" p="20px" >
           Privacy of e-mail lists
        </Heading>   
            <Text fontWeight="500">
            Individuals who join our mailing lists via website or any volunteering campaign are added to our
            email database. UPCHILD FOUNDATION does not lease, sell, rent, loan or trade the addresses on our
            lists to anyone.
            </Text>
        
        {/* <Heading  as='h3' size='sm' textAlign="end" textColor={"#319795"} fontWeight="bold" p="20px" >
             Cookie Policy
        </Heading> 
        <OrderedList> 
            <ListItem fontWeight="500">
                “Cookies” are small amounts of data that the website can sends to the browser on user’s
                computer. A cookie may enable the site holder to track how the user navigates through its
                site and area it shows interest in.</ListItem>
            <ListItem fontWeight="500"> Cookie can be set to expire within a given time limit. For example, it can be set to expire
                on a specific date after specific time after the transaction is complete or user closes the
                browser.</ListItem>
            <ListItem fontWeight="500"> To browse anonymously, the user can either disable or delete the cookies. Although,
                disabling cookies for the services will impact the overall browsing experience.</ListItem>
            <ListItem fontWeight="500"> The user can configure his/her browser in a way that he/she deems fit. For example, the
                user may want to accept all the cookies, reject them all or get notified each time cookie is
                sent.</ListItem>
        </OrderedList> */}

        <Heading  as='h3' size='sm' textAlign="end" textColor={"#319795"} fontWeight="bold" p="20px" >
            Payment Gateway
        </Heading>  
            <Text fontWeight="500">
            UPCHILD FOUNDATION uses well-recognised, safe and reliable technology for payments. Payment
            information is transferred through highest degree of security that the donor’s browser is able to
            support.
            </Text>

        <Heading  as='h3' size='sm' textAlign="end" textColor={"#319795"} fontWeight="bold" p="20px" >
            External Web Services
        </Heading> 
            <OrderedList>
                <ListItem fontWeight="500">UPCHILD FOUNDATION uses many external web services on its site to display content within
                    its web pages. For example, using social media icons which re-direct the user to the
                    social media handle.
                </ListItem>
                <ListItem fontWeight="500">UPCHILD FOUNDATION website contains links to other websites for the benefit of its visitors.
                    This Privacy Policy does not apply to such other websites.
                </ListItem>
                <ListItem fontWeight="500"> UPCHILD FOUNDATION is not expressly or impliedly responsible for, or liable to any loss or
                    damage caused to a user by the collection, use and retention of Personal Information by
                    such website in any manner whatsoever. It is important that the users review the privacy
                    policies of all websites they visit before disclosing any information to such websites.
                </ListItem>
            </OrderedList>

        <Heading  as='h3' size='sm' textAlign="end" textColor={"#319795"} fontWeight="bold" p="20px" >
          Changes to the policy
        </Heading>
        <OrderedList>
            <ListItem fontWeight="500">
            UPCHILD FOUNDATION is entitled to make any changes during any period of time as per the
            requirement. In case of any major changes in the policy, the users will be informed via
            email.
            </ListItem>
            <ListItem fontWeight="500">
            For any queries regarding UPCHILD FOUNDATION’s Privacy Policy, please mail us at
            foundationupchild@gmail.com
            </ListItem>
        </OrderedList>     

    </Container>
      <UpChildFooter />
    </div>
  );
};
export default PrivacyPolicy;
